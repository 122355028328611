import PrivacyStatement from '@pages/privacyStatement'
import Reminder from '@pages/reminder'
import UserAgreement from '@pages/userAgreement'
import DemoStatement from '@pages/demoStatement'

const routes = [
  {
    path: '/privacyStatement',
    component: PrivacyStatement,
  },
  {
    path: '/reminder',
    component: Reminder,
  },
  {
    path: '/userAgreement',
    component: UserAgreement,
  },
  {
    path: '/demoStatement',
    component: DemoStatement,
  },
]

export default routes