// 用户协议
import React from 'react'

export default function UserAgreement() {
  return (
    <div className="main-container">
      <h2 className="main-title-level-1">
        用户协议
      </h2>
      <h4 className="main-title-level-2">
      一、 用户须知：
      </h4>
      <h4 className="main-title-level-3">
      1.1 您在使用【杭州随寓网络科技有限公司】（以下简称“随寓”、“我们”、“本平台”）提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容。
      </h4>
      <h4 className="main-title-level-3">
      1.2 您一旦在本网站/本应用注册，即视为您已了解并完全同意本服务协议各项内容，并成为随寓用户。
      </h4>
      <h4 className="main-title-level-2">
      二、 协议的约束效力
      </h4>
      <h4 className="main-title-level-3">
      2.1 若您成功注册成为随寓网站/应用的用户，该行为表示您同意并签署了本协议，本协议即在您和随寓之间产生法律效力。请您务必在注册之前认真阅读本协议内容，如有任何疑问，可致电我们的客服电话400-060-2897咨询。
      </h4>
      <h4 className="main-title-level-3">
      2.2 您确认，在您完成注册程序或以其他随寓允许的方式实际使用随寓平台服务时，您应当是具备相应民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人应承担因此而导致的一切后果，且随寓有权注销您的账户。
      </h4>
      <h4 className="main-title-level-3">
      2.3 用户注册成功后，每个用户拥有对应的帐号及密码，该帐号和密码由用户使用并保管；用户应当对以其用户帐号进行的所有活动和事件承担法律责任。
      </h4>
      <h4 className="main-title-level-2">
      三、 帐号注册、使用及注销
      </h4>
      <h4 className="main-title-level-3">
      3.1 随寓的帐号的所有权归随寓，用户按注册页面引导填写信息，阅读并同意本协议且完成全部注册程序后，即可获得随寓帐号并成为随寓用户。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料。若因注册信息不真实或更新不及时引起的相应责任及后果由用户承担，且随寓保留终止用户使用随寓各项服务的权利。
      </h4>
      <h4 className="main-title-level-3">
      3.2 用户提交的注册信息中不得出现违法和不良信息，经随寓审核，如存在上述情况，随寓有权不予注册；同时，在注册后，如发现用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，随寓有权采取限期改正、暂停使用、注销登记、收回等措施。
      </h4>
      <h4 className="main-title-level-3">
      3.3 随寓会在您首次注册或使用随寓平台服务的情况下自动存储您的相关信息。如您发现您的帐号遭他人非法使用，应立即通知随寓。如因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，相应后果由您自行承担。
      </h4>
      <h4 className="main-title-level-3">
      3.4 我们以匿名方式取得您在使用本平台服务过程中的相关信息，并对信息采取加密处理，保证信息的安全性。
      </h4>
      <h4 className="main-title-level-3">
      3.5 随寓将合理使用所存储的信息，使用范围包括1、为了让您更多地了解市场变化趋势，及时掌握有关讯息，我们会根据您预留的联系方式，
      不定期向您推送最新的房产知识及行情变化；2、为了更好地服务客户，我们会不断地对本网站系统进行技术更新，
      在此过程中可能需要使用您的信息进行技术改造相关工作；3、我们可能会对随寓服务的使用情况进行统计。同时，
      随寓可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势或市场行情的客观情况，这些统计信息不包含您的任何身份识别信息；
      </h4>
      <h4 className="main-title-level-3">
      3.6 请您不要将您的帐号、密码转让或出借予他人使用。如您发现您的帐号遭他人非法使用，应立即通知随寓。
      </h4>
      <h4 className="main-title-level-3">
      3.7 当您使用随寓产品进行支付、登录等操作的时候，服务器会自动收取并记录一些必要的信息，如IP地址、服务访问异常信息、以及部分设备信息等，以保障您在使用随寓服务时账户登录和支付过程的安全，进而保护您的上网安全，关于随寓使用用户信息详细规定请见《用户隐私政策》。
      </h4>
      <h4 className="main-title-level-3">
      3.8 在如下情况，随寓可能会披露您的信息:
      </h4>
      <ul className="ul-list">
        <li className="li-item">
        （1）事先获得您的授权；
        </li>
        <li className="li-item">
        （2）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；
        </li>
        <li className="li-item">
        （3）以学术研究或公共利益为目的；
        </li>
        <li className="li-item">
        （4）为维护随寓的合法权益，例如查找、预防、处理欺诈或安全方面的问题；
        </li>
        <li className="li-item">
        （5）危及您的人身或财产重大安全，情况紧急无法取得您事前同意的；
        </li>
        <li className="li-item">
        （6）符合相关服务条款或使用协议的规定。
        </li>
      </ul>
      <h4 className="main-title-level-3">
      3.9 您知悉并授权，随寓仅在必需的情况下使用或与关联公司同步您的信息，以为用户提供相关服务。
      </h4>
      <h4 className="main-title-level-3">
      3.10 为更好地向用户提供服务，您同意随寓通过短信等形式向您发送相关服务信息。
      </h4>
      <h4 className="main-title-level-2">
      四、用户使用规范
      </h4>
      <h4 className="main-title-level-3">
      4.1 用户在使用随寓平台服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本平台进行任何违法或不正当的活动，包括但不限于下列行为:
      </h4>
      <h4 className="main-title-level-3">
      （1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息
      </h4>
      <ul className="ul-list">
        <li className="li-item">
        1）反对宪法所确定的基本原则的；
        </li>
        <li className="li-item">
        2）煽动危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </li>
        <li className="li-item">
        3）损害国家荣誉和利益的；
        </li>
        <li className="li-item">
        4）煽动民族仇恨、民族歧视、破坏民族团结的；
        </li>
        <li className="li-item">
        5）破坏国家宗教政策，宣扬邪教和封建迷信的；
        </li>
        <li className="li-item">
        6）散布谣言，扰乱社会秩序，破坏社会稳定的；
        </li>
        <li className="li-item">
        7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </li>
        <li className="li-item">
        8）侮辱或者诽谤他人，侵害他人合法权利的；
        </li>
        <li className="li-item">
        9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它内容；
        </li>
        <li className="li-item">
        10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
        </li>
      </ul>
      <h4 className="main-title-level-3">
      （2）不得为任何非法目的而使用网络服务系统；
      </h4>
      <h4 className="main-title-level-3">
      （3）不利用随寓平台从事以下活动：
      </h4>
      <ul className="ul-list">
        <li className="li-item">
        1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
        </li>
        <li className="li-item">
        2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；
        </li>
        <li className="li-item">
        3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
        </li>
        <li className="li-item">
        4）故意制作、传播计算机病毒等破坏性程序的；
        </li>
        <li className="li-item">
        5）其他危害计算机信息网络安全的行为。
        </li>
      </ul>
      <h4 className="main-title-level-3">
      4.2 用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔或损失，用户自行承担责任；由此给随寓及其合作公司或关联方造成损失的，您同意予以赔偿。
      </h4>
      <h4 className="main-title-level-3">
      对此，随寓有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收随寓帐号、追究法律责任等措施；对恶意注册随寓帐号或利用随寓帐号进行违法活动、捣乱、骚扰、欺骗其他用户以及其他违反本协议的行为，随寓有权回收其帐号。同时，随寓公司会视司法部门的要求，协助调查。
      </h4>
      <h4 className="main-title-level-3">
      4.3 用户不得对平台任何部分或本平台之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。
      </h4>
      <h4 className="main-title-level-3">
      4.4 用户须对自己在使用本平台服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在随寓公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予随寓公司等额的赔偿。
      </h4>
      <h4 className="main-title-level-2">
      五、服务内容
      </h4>
      <h4 className="main-title-level-3">
      5.1 随寓网络服务的具体内容由随寓根据实际情况提供
      </h4>
      <h4 className="main-title-level-3">
      5.2 除非本使用协议另有其它明示规定，随寓所推出的新产品、新功能、新服务，均受到本协议之规范。
      </h4>
      <h4 className="main-title-level-3">
      5.3 免责声明：因以下情况造成网络服务在合理时间内的中断，随寓不承担责任：
      </h4>
      <ul className="ul-list">
        <li className="li-item">
        （1）随寓需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，随寓保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利；
        </li>
        <li className="li-item">
        （2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；
        </li>
        <li className="li-item">
        （3）用户的电脑软硬件和通信线路、供电线路出现故障的；
        </li>
        <li className="li-item">
        （4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。 尽管有前款约定，随寓将采取合理行动积极促使服务恢复正常。
        </li>
      </ul>
      <h4 className="main-title-level-3">
      5.4 终止服务：
      </h4>
      <p className="common-p">
      您同意随寓得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或随寓认为您已经违反本协议，终止您的帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。
      </p>
      <p className="common-p">
      您同意依本协议约定，随寓无需进行事先通知即可中断或终止您对本平台服务的使用；您同意，随寓可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本平台服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，随寓对您或任何第三人均不承担任何责任。
      </p>
      <h4 className="main-title-level-2">
      六、法律适用、管辖与其他
      </h4>
      <h4 className="main-title-level-3">
      6.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
      </h4>
      <h4 className="main-title-level-3">
      6.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向北京市朝阳区人民法院提起诉讼。
      </h4>
      <h4 className="main-title-level-3">
      6.3 随寓未行使或执行本服务协议任何权利或规定，不构成对前述权利之放弃。
      </h4>
      <h4 className="main-title-level-3">
      6.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
      </h4>
      <h4 className="main-title-level-3">
      6.5 随寓有权在必要时修改本协议条款，条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的随寓平台服务。如果用户继续享用随寓平台服务，则视为接受服务条款的变动。
      </h4>
      <p className="bottom-mark">
      杭州随寓网络科技有限公司
      </p>
      <p className="bottom-mark">
      二零二一年八月二十八日
      </p>
    </div>
  )
}