// 温馨提示
import React from 'react'

export default function Reminder() {
  return (
    <div className="main-container">
      <h2 className="main-title-level-1">
      温馨提示
      </h2>
      <h4 className="main-title-level-2">
      欢迎您使用随寓APP
      </h4>
      <h4 className="main-title-level-2">
      依据最新的监管要求，请您阅读并同意《随寓隐私政策》，特向您声明如下：
      </h4>
      <h4 className="main-title-level-3">
      1、为保障您的账户安全，我们将收集您所使用的设备相关信息；
      </h4>
      <h4 className="main-title-level-3">
      2、基于您的明示授权，我们可能会获取您的位置信息（为您精准推荐周边房源）、通话功能（便于您与咨询人联系）等；
      </h4>
      <h4 className="main-title-level-3">
      3、为提升您的浏览体验，我们将缓存部分图片，为此需读取您的SD卡权限，若您需要提交图片信息时（报修拍照），我们需要调取您的相册权限；
      </h4>
      <h4 className="main-title-level-3">
      4、未经您同意，我们不会从第三方处获取、共享或向其提供您的信息；
      </h4>
      <h4 className="main-title-level-3">
      5、您可以在系统中撤回相关授权设置。
      </h4>
    </div>
  )
}