import React, {useEffect, Suspense} from 'react'
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import {Provider} from 'mobx-react'
import RouteWithSubRoutes from '@components/routeWithSubRoutes'
import store from './store'
import routes from './router'
import './i18/i18n'
// import './icon-svg'
import 'antd/dist/antd.css'
import './App.less'

function App() {
  return (
    <Provider store={store}>
      <div className="App" id="app">
        <Suspense fallback={<div />}>
          <Router>
            <Switch>
              {
                routes.map(route => (
                  <RouteWithSubRoutes {...route} key={route.path} />
                ))
              }
              <Redirect from="/" exact to="/privacyStatement" />
            </Switch>
          </Router>
        </Suspense>
      </div>
    </Provider>
  );
}

export default App;
