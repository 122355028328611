// 隐私声明
import React from "react";

export default function PrivacyStatement() {
  return (
    <div className="main-container">
      <p className="con-p-1">
        <span className="con-text-1">隐私政策</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          本隐私声明（包括但不限于本协议项下全部条款以及与本协议相关的通过平台发布或未来可能发布的各项规则，以下简称“本协议”）系杭州随寓网络科技有限公司（以下简称“本公司”）拟定并发布的，用于规范平台注册用户（以下简称“用户”或“您”）在平台上注册以及使用平台服务的行为。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          在您注册成为随寓用户之前，请务必确认：您是具有完全民事权利能力及民事行为能力、有能力履行并承担本协议项下的权利及义务、并能够独立作为法律诉讼的一方，
        </span>
        <span className="con-text-2">
          您已认真阅读本协议项下全部条款，一旦您确认接受本协议，即表示您已经完全阅读、理解、同意接受并遵守本协议全部条款。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          随寓系由本公司研发运营管理，本公司对本协议享有修改及解释之权利。如本协议有任何修改变更，本公司将在随寓官网及
        </span>
        <span className="con-text-3">App</span>
        <span className="con-text-2">
          刊载相关变更事项公告，并不再单独以其它方式通知您。所有修订的条款和规则一经公告立即生效，并对您产生法律约束力。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          如您对修改变更的内容有异议，请您立即停止使用随寓并联系我们；如您继续使用随寓的任何服务，则视为您已经同意并接受所有修改变更内容。当您与随寓发生纠纷时，所有条款及规则应以最新版本为准
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          注册页面的“注册即代表已阅读并同意《随寓用户协议和隐私声明》”或首次在手机
        </span>
        <span className="con-text-3">app</span>
        <span className="con-text-2">
          软件上点击“登录”选项取得账号、密码及
        </span>
        <span className="con-text-3">/</span>
        <span className="con-text-2">
          或验证码，即视为您对本协议的签署；在任何情况下，您都不得以未签署纸质协议为由否认本协议各条款及效力。若因您对本协议的违反导致任何法律后果的发生，您应以自己的名义独立承担相应的全部责任。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">第一部分 范围及定义</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">一、规范性引用文件</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          下列文件对于本文件的应用是必不可少的。凡是注日期的引用文件，仅所注日期的版本适用于本文件。凡是不注日期的引用文件，其最新版本（包括所有的修改单）适用于本文件。
        </span>
      </p>
      <p className="con-p-3">
        <span className="con-text-3">GB/T 22239-2008</span>
        <span className="con-text-2">信息系统安全等级保护基本要求</span>
      </p>
      <p className="con-p-3">
        <span className="con-text-3">GB/T 18336-2015</span>
        <span className="con-text-2">信息技术安全评估准则</span>
      </p>
      <p className="con-p-3">
        <span className="con-text-3">GB/Z 28828-2012</span>
        <span className="con-text-2">
          公共及商用服务信息系统个人信息保护指南
        </span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">
          移动互联网应用程序信息服务管理规定（国家互联网信息办公室{" "}
        </span>
        <span className="con-text-3">2016</span>
        <span className="con-text-2">年</span>
        <span className="con-text-3">6</span>
        <span className="con-text-2">月</span>
        <span className="con-text-3">28</span>
        <span className="con-text-2">日发布）</span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">
          中华人民共和国网络安全法（由全国人民代表大会常务委员会于
        </span>
        <span className="con-text-3">2016</span>
        <span className="con-text-2">年</span>
        <span className="con-text-3">11</span>
        <span className="con-text-2">月</span>
        <span className="con-text-3">7</span>
        <span className="con-text-2">日发布，自</span>
        <span className="con-text-3">2017</span>
        <span className="con-text-2">年</span>
        <span className="con-text-3">6</span>
        <span className="con-text-2">月</span>
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          日起施行。中华人民共和国主席令（第五十三号）公布。）
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">第二部分 用户协议</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">一、注册账号</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（一）提供个人信息</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          、您有义务按照随寓的要求，及时、详尽、准确地提供您真实的身份信息及个人资料（下称个人信息），并在个人信息发生变更时及时更新，您需要提供的个人信息包括但不限于姓名、性别、年龄、联系电话、电子邮箱、目前职业、通讯地址、紧急联系人、星座、兴趣爱好等。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">2</span>
        <span className="con-text-2">
          、如随寓发现您提供的个人信息不完整、不真实或错误的，有权要求您及时更新、补正个人信息，或直接单方面暂时性或永久性的停止您随寓账号的部分或全部使用权限。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">3</span>
        <span className="con-text-2">
          、您承诺，您所提供的个人信息资料完整、真实、无误，若因信息不完整、不真实或错误产生的任何问题、不良后果（包括任何直接损失及间接损失）、法律责任均由您自行承担，随寓不承担任何责任。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（二）账号及密码</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          注册成功后，您将获得一个随寓账号（下称账号）及其对应的密码。如发现账号及密码无法匹配对应，请及时联系随寓客服
        </span>
        <span className="con-text-3">400-060-2897</span>
        <span className="con-text-2">。</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">二、个人隐私保护</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          尊重和保护每一位随寓客的个人隐私是随寓的基本原则。随寓将根据隐私声明之内容对您个人隐私进行保护，隐私声明属于本用户协议不可分割的一部分。您承诺已经充分阅读、理解、同意并接受随寓据此处理您的个人信息。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">三、账号安全</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（一）妥善保管账号及密码</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          、您的账号、密码及提供的个人信息是您在随寓的唯一识别信息。您注册成功后，请您严格履行对其的保密及保管，切勿将其转让、出售或授权给任何第三方使用。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">2</span>
        <span className="con-text-2">、若您主动告知他人您的账号密码</span>
        <span className="con-text-3">/</span>
        <span className="con-text-2">
          验证码或与他人共用账号，随寓有权认为该账号的所有操作均代表您的本人意愿，并且由此产生的任何问题、不良后果（包括直接损失及间接损失）、法律责任均由您自行承担，随寓不承担任何责任。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">3</span>
        <span className="con-text-2">
          、若您发现有第三人冒用、盗用您的账号及密码，应立即通知随寓停止您的账号服务，否则，由此产生的任何问题、不良后果（包括任何直接损失及间接损失）、法律责任均由您自行承担，随寓不承担任何责任。并且，由于随寓对您的请求需要采取行动的合理时间，故在随寓停止您的账号服务前，随寓对第三人使用您的账号进行的操作所带来的损失不承担任何责任
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">4</span>
        <span className="con-text-2">、您在此承诺，您的账号及密码</span>
        <span className="con-text-3">/</span>
        <span className="con-text-2">
          验证码在登陆随寓后，所从事的一切行为均代表您本人，并由您本人承担相应的法律后果。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（二）账号及密码修改、找回：</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          、账号密码修改：如您需修改您已注册的账号或密码，请严格按照随寓的提示信息经过身份验证（手机或电子邮箱等）后操作，或者直接致电随寓进行身份验证后，由随寓为您修改。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">2</span>
        <span className="con-text-2">
          、账号密码找回：如您遗忘您已经注册的账号或密码，请严格按照随寓的提示信息经过身份验证（手机或电子邮箱等）后操作，或者直接致电随寓进行身份验证后，由随寓为您重置账号密码。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">四、账号使用规范</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          您同意并承诺在使用账号时，应当遵守中华人民共和国法律法规及各项政策，维护随寓品牌形象，不会利用账号从事以下活动，或在使用账号过程中存在以下行为。否则，随寓有权立即停止您账号的部分或全部使用权限，并有权追究您相应的法律责任（若有）：
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （一）上载、展示、发送、传播、散布或者以其他方式传送含有下列内容之一的信息，随寓有权立即删除部分或全部该等信息
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          、违反中华人民共和国法律法规及各项政策的非法信息，包括但不限于危害国家安全、泄露国家秘密、颠覆国家政权、破坏宗教政策、邪教封建迷信、危害国家或社会公共利益、涉及暴力、淫秽、色情、赌博、恐怖、犯罪的信息；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">2</span>
        <span className="con-text-2">
          、通过捏造、编造、臆想等方式虚构的不实信息
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">3</span>
        <span className="con-text-2">
          、恶意诋毁、诽谤、中伤、恐吓、威胁、骚扰他人的信息
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">4</span>
        <span className="con-text-2">
          、侵犯他人名誉权，隐私权、知识产权、商业秘密的信息；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">5</span>
        <span className="con-text-2">
          、恶意诋毁、诽谤、恐吓、中伤、威胁、骚扰随寓官网管理员、杭州随寓网络科技有限公司或其员工的信息；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">6</span>
        <span className="con-text-2">
          、侵害随寓品牌名誉、杭州随寓网络科技有限公司商业秘密的信息；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">7</span>
        <span className="con-text-2">
          、其他中华人民共和国法律法规政策、社会道德、公序良俗所禁止或限制的信息。
        </span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">（</span>
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          ）非法利用病毒、软件、程序等破坏、侵害随寓系统，干扰随寓的正常运营的
        </span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">（</span>
        <span className="con-text-3">2</span>
        <span className="con-text-2">
          ）非法截取、盗取、改变、增删系统数据、功能、程序的；
        </span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">（</span>
        <span className="con-text-3">3</span>
        <span className="con-text-2">）以非法目的使用账号的；</span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">（</span>
        <span className="con-text-3">4</span>
        <span className="con-text-2">）长时间不使用；</span>
      </p>
      <p className="con-p-3">
        <span className="con-text-2">（</span>
        <span className="con-text-3">5</span>
        <span className="con-text-2">
          ）其他危害或可能危害随寓安全的行为。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">五、服务内容</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（一）业务介绍</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          您已理解并知悉：随寓经营的房屋租赁业务是符合中华人民共和国法律法规、地方政策及相关制度的租赁业务，且并非仅限于或仅针对某性别、某群体。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（二）房源信息展示</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          您可以在随寓上阅览、查询、搜集随寓平台上的房源及相关信息，包括房源的地址、楼栋、面积、朝向、费用价格、促销优惠等。您理解，鉴于可能存在系统更新延时等情况，该等信息可能存在不准确之情况，具体情况请以随寓管家或者与随寓联系后得到的确认信息为准。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（三）活动介绍</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          您可以通过随寓上公布的相关活动了解随寓最新的线上及线下活动信息，并根据活动规则报名参与。您理解，鉴于可能存在系统更新延时等情况，该等信息可能存在不准确之情况，具体情况请以随寓管家或者与随寓联系后得到的确认信息为准
        </span>
        <span className="con-text-3">;</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">（四）投诉、意见反馈</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">1</span>
        <span className="con-text-2">
          、投诉：您在成功注册账号后，可通过随寓特定板块进行投诉，或者直接致电随寓客服热线：
        </span>
        <span className="con-text-3">400-060-2897</span>
        <span className="con-text-2">
          ，随寓将尽快回复您，并及时安排上门维修服务或处理您的投诉
        </span>
        <span className="con-text-3">;</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">2</span>
        <span className="con-text-2">
          、意见反馈：您在成功注册账号后，可通过随寓特定板块提出您对随寓的意见和建议。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">六、免责声明</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          您已经充分阅读、理解、同意并接受，鉴于网络服务之特殊性，随寓无法担保以下内容，并且对以下内容不承担法律责任：
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （一）随寓不能保证提供的网络服务一定满足您的要求；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （二）随寓不能保证外部网络链接的真实性、安全性、准确性、完整性，因外部链接指向的为不由随寓实际控制的网页内容；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （三）随寓不能控制网络服务因所依赖的电信设备故障、不可抗力、黑客攻击、电信部门技术调整或故障、或其他随寓无法控制的原因造成的随寓部分或全部服务暂停、中断、无法使用、或者其他缺陷问题，但随寓承诺将尽力减少因此给您造成的损失和影响。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">七、知识产权声明</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （一）著作权、专利权：随寓在所提供的全部内容及网络服务的过程中，包含、涉及、提及的文本、图片、图形、图像、视频、软件、程序、程序代码等（统称网站内容）之著作权及
        </span>
        <span className="con-text-3">/</span>
        <span className="con-text-2">
          或专利权均受法律保护，未经相关权利人同意，任何自然人、法人或其他组织不得擅自直接或间接转载、引用、使用或者以其他方式复制发表发布，但法定许可的情形除外。其中属于随寓所有之部分，必须经过随寓书面授权许可。如需转载、引用、使用或者以其他方式复制发表发布随寓网站内容，或者磋商支付法定许可报酬，请联系随寓客服热线：
        </span>
        <span className="con-text-3">400-060-2897</span>
        <span className="con-text-2">。</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （二）商标：“随寓“以及随寓图形商标是随寓的商标及
        </span>
        <span className="con-text-3">/</span>
        <span className="con-text-2">
          或注册商标，未经随寓明确书面授权擅自非法使用（包括但不限于复制、展示、传播、发送、上载、下载）的，随寓将依法追究其法律责任。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">八、法律责任</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          因您违反本协议中的承诺、保证或任何其他义务，或因您的承诺、保证不真实、不准确、不完整或具有误导性，致使随寓遭受损失的，随寓有权要求您赔偿全部损失并依法追究您的法律责任。如造成第三方损失的，您应当独立向第三方承担相应的全部赔偿责任。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">九、通知送达</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （一）您有义务根据随寓的要求提供您的真实资料，并保证您的通讯地址、联系电话、电子邮箱地址、紧急联系人等个人通信信息的真实性及有效性，以便随寓将该等信息作为联系您的合法依据。如有该等信息有变更，您有义务及时更新您的账号信息或直接致电随寓客服热线：
        </span>
        <span className="con-text-3">400-060-2897</span>
        <span className="con-text-2">
          进行变更。如通过您提供的个人通讯信息无法联络到您，由此产生的一切损失或增加的额外费用均由您独立承担。{" "}
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （二）您同意并接受：随寓通过您提供的个人通讯信息联系您，并会定期或不定期地向您推送、告知、送达一些消息和通知。任何消息和通知于随寓发出之日起，即视为已经送达用户，您不得以任何理由否认该等信息的效力或拒绝履行该等信息下用户的相应义务。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">十、其他规定</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （一）未成年人特别提示：如您为未成年人（未满
        </span>
        <span className="con-text-3">18</span>
        <span className="con-text-2">
          周岁），随寓提示您，请遵守全国青少年网络文明公约，您无权单独使用随寓的部分或全部功能及服务，请在父母或监护人的陪同下使用随寓服务。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （二）权利义务转让：您同意随寓有权将本协议项下部分或全部权利和义务，转让给其他第三方，且无需告知您并征得您的同意。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （三）随寓未行使本协议中的任何权利，不构成对前该等权利的放弃。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （四）本用户协议中的任何条款与中国法律强制性规定冲突导致其全部或部分无效的，不影响本协议其余条款的效力。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （五）终止：除非随寓终止本协议，或您根据法律法规及本协议的约定申请终止本协议并经随寓同意，本协议长久有效。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （六）法律适用：本协议的签订、履行、变更、终止、解释均适用中华人民共和国法律。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          （七）法律管辖：因本协议或者随寓服务产生的一切纠纷及争议应向随寓所在地的人民法院提起诉讼。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">第三部分 隐私声明</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          随寓致力于尊重并保护用户的个人隐私，鉴于网络的特性，我们无可避免地需要某些信息才能为您提供您请求的服务，本隐私声明（以下简称“本声明”）将阐述我们对用户信息的收集、使用、保护和共享政策，我们建议您完整地阅读本声明。
          一旦您确认接受本声明，即表示您已经完全阅读、理解、同意接受本声明的全部条款。
          本声明适用于随寓的所有服务，随着随寓服务范围的扩大，随寓可随时更新隐私声明的内容，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效，并代替原来的隐私声明。欢迎您随时查看本声明，并向我们反馈您的意见。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">一、信息的收集与使用</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">我们将通过您的</span>
        <span className="con-text-3">IP</span>
        <span className="con-text-2">
          地址来收集非个人化的信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的
        </span>
        <span className="con-text-3">ISP</span>
        <span className="con-text-2">
          的域名等，以优化在您手机或计算机等屏幕上显示的页面。通过收集上述信息，我们进行客流量统计，从而改进网站的管理或服务。
          通常，您能在匿名的状态下访问随寓并获取信息。当我们需要可用于标识您的个人身份或允许我们与您联系的信息时，我们会征求您的同意。当您在随寓进行注册登记或使用我们提供的各项服务时，您需要向我们提供您的个人信息，这些个人信息包括但不限于
        </span>
        <span className="con-text-2">：</span>
        <span className="con-text-2">
          个人识别信息：如姓名、性别、身份证号码、电子邮件地址，住址和电话号码等信息。
          个人背景：如年龄、出生日期、职业、教育程度、星座、收入状况、婚姻、家庭状况等。我们提供的服务可能包括属于我们的服务供应商的第三方追踪工具。该第三方可能会在我们提供的服务中使用
        </span>
        <span className="con-text-3">cookies</span>
        <span className="con-text-2">、</span>
        <span className="con-text-3">APls(</span>
        <span className="con-text-2">应用程序编程接口</span>
        <span className="con-text-3">)</span>
        <span className="con-text-2">及</span>
        <span className="con-text-3">SDKs(</span>
        <span className="con-text-2">软件程序开发包</span>
        <span className="con-text-3">)</span>
        <span className="con-text-2">
          ，由此代表我们收集并分析用户信息。第三方可能会访问诸如您的设备标识符、
        </span>
        <span className="con-text-3">MAC(</span>
        <span className="con-text-2">媒体访问控制</span>
        <span className="con-text-3">)</span>
        <span className="con-text-2">地址、</span>
        <span className="con-text-3">IME</span>
        <span className="con-text-2">、</span>
        <span className="con-text-3">IMSI</span>
        <span className="con-text-2">、</span>
        <span className="con-text-3">ME(</span>
        <span className="con-text-2">移动设备国际身份码</span>
        <span className="con-text-3">)</span>
        <span className="con-text-2">、区域</span>
        <span className="con-text-3">(</span>
        <span className="con-text-2">使用给定语言的特定地点</span>
        <span className="con-text-3">)</span>
        <span className="con-text-2">、地理位置信息及地址</span>
        <span className="con-text-2">，应用内集成的</span>
        <span className="con-text-3">[</span>
        <span className="con-text-2">友盟</span>
        <span className="con-text-3">]SDK</span>
        <span className="con-text-2">收取</span>
        <span className="con-text-3">MAC</span>
        <span className="con-text-2">地址</span>
        <span className="con-text-3">,ANDROID ID; </span>
        <span className="con-text-2">应用内集成的</span>
        <span className="con-text-3">[TBS</span>
        <span className="con-text-2">腾讯浏览服务、腾讯浏览服务、腾讯</span>
        <span className="con-text-3">X5</span>
        <span className="con-text-2">浏览器、腾讯浏览器</span>
        <span className="con-text-3">]SDK</span>
        <span className="con-text-2">收取设备</span>
        <span className="con-text-3">ID,IMSI,ANDROID ID</span>
        <span className="con-text-2">
          等信息，旨在实现在其各自隐私政策下提供服务。在某些服务中，我们将利用加密技术
        </span>
        <span className="con-text-3">(</span>
        <span className="con-text-2">例如</span>
        <span className="con-text-3">ssL)</span>
        <span className="con-text-2">
          来保护您的个人信息。我们没有权限访问或控制这些第三方。如果您想知道更多相应第三方信息，请联系我们
        </span>
        <span className="con-text-3">400-060-2897.</span>
      </p>
      <p className="con-p-4">
        <span className="con-text-4">
          为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">具体如下：</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：友盟统计分析 SDK
        </span>
        <br />
        <span className="con-text-4">
          合作方：友盟同欣（北京）科技有限公司
        </span>
        <br />
        <span className="con-text-4">
          合作目的：提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。
        </span>
        <br />
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </span>
        <br />
        <span className="con-text-4">
          个人信息收集范围：采集设备标识符(IMEI/Mac/android
          ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)、位置信息
        </span>
        <br />
        <span className="con-text-4">
          SDK简称：友盟应用性能监控SDK
        </span>
        <br />
        <span className="con-text-4">
          合作方：友盟同欣（北京）科技有限公司
        </span>
        <br />
        <span className="con-text-4">
          合作目的：提供设备应用性能监控服务，通过采集位置信息提供反作弊服务，剔除作弊设备，排查应用性能崩溃原因。
        </span>
        <br />
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </span>
        <br />
        <span className="con-text-4">
          个人信息收集范围：采集设备标识符(IMEI/Mac/android
          ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)、位置信息
        </span>
        <br />
        <span className="con-text-4">
          SDK简称：友盟社会化分享SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：友盟同欣（北京）科技有限公司
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：向目标设备分享消息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：采集设备标识符(IMEI/Mac/android
          ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)、位置信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">&#xa0;</span>
        <span className="con-text-4">
          SDK简称：友盟智能认证 SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：友盟同欣（北京）科技有限公司
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：用于提供手机号码一键登录服务。采集地理位置甄别分享通道，提供反作弊服务。
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：采集设备标识符(IMEI/Mac/android
          ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息)以及手机号码
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：中国移动号码认证SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">合作方：中国移动</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方类型：免密号码友盟合作方
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：提供移动端设备移动手机号免密号码验证服务
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：当前手机号的掩码
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：中国联通号码认证SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">合作方：中国联通</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方类型：免密号码友盟合作方
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：提供移动端设备联通手机号免密号码验证服务
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集个人信息、设备信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：当前手机号的掩码
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">&#xa0;</span>
        <span className="con-text-4">
          SDK简称：中国电信号码认证SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">合作方：中国电信</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方类型：免密号码友盟合作方
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：提供移动端设备电信手机号免密号码验证服务
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：当前手机号的掩码
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK 名称：高德地图定位SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：高德软件有限公司
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：加油站导航服务，支持美团外卖优惠的定位服务
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：查看WLAN连接；粗略定位；精确定位；读取外置存储卡；读取手机状态身份；写入外置存储卡；后台访问地理位置
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：腾讯x5浏览器内核{" "}
        </span>
        <span className="con-text-4">&#xa0;</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：深圳市腾讯计算机系统有限公司{" "}
        </span>
        <span className="con-text-4">&#xa0;</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：移动浏览场景体验优化
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传个人信息、设备信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：常用设备信息（如IMEI/IMSI、SIM卡序列号/MAC地址、android_id）、设备制造商、网络类型、网络状态、手机操作系统、屏幕尺寸、ip地址
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">SDK简称：统一SDK</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：移动安全联盟
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：获取设备唯一标识符
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：UDID、OAID、VAID、AAID
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：MobTech PushSDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：上海游昆信息技术有限公司
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：为了实现消息功能
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息，对接第三方接口，接口传输个人信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：系统运营信息、网络状态信息、iOS广告标识符（IDFA）、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID
          、地理位置
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：MobTech MobLinkSDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：上海游昆信息技术有限公司
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：为了提供场景还原功能
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：系统运营信息、网络状态信息、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID
          、地理位置
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          SDK简称：百度地图SDK
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方：百度在线网络技术（北京）有限公司
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作方类型：定位位置服务合作方
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          合作目的：提供用户位置服务，帮助用户在发布信息时定位位置
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集方式：嵌入第三方SDK，SDK收集传输个人信息
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、Wi-Fi地址
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          个人信息字段定义以及穷举
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          设备MAC地址：设备的mac地址，确认移动设备位置的地址
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          唯一设备识别码：iOS设备为IDFA，Android设备为IMEI、oaid、AndroidId、UUID（Android以上识别码可能会收集多个）
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          设备IP地址：移动互联网链接协议，确认网络连接的运营商服务
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          设备信息：设备品牌、设备型号、操作系统、操作系统版本
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          网络信息：当前网络环境（如WIFI、5G、4G、3G、2G）
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          运营商信息：号段归属移动、联通、电信类型
        </span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">
          应用列表：判断是否安装了合作的第三方APP
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">二、关于您的个人信息</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          请了解，随寓严格保护您个人信息的安全，一经注册，随寓仅会公开您的性别、星座、职业、爱好，您的其余个人信息将严格保密，未经您的同意，我们不会将您提供的个人信息利用于其它商业目的，我们将使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄露。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          您理解并同意，随寓仅会在法律要求或符合随寓的相关服务条款、软件许可使用协议约定的情况下透露您的个人信息，或者有充分理由相信必须这样做才能：
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">(a) </span>
        <span className="con-text-2">
          满足法律或行政法规的明文规定，或者符合随寓适用的法律程序；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">(b)</span>
        <span className="con-text-2">
          符合随寓相关服务条款、软件许可使用协议的约定；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">(c) </span>
        <span className="con-text-2">保护随寓的权利或财产；</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">(d) </span>
        <span className="con-text-2">
          在紧急情况下保护随寓员工、随寓产品或服务的用户或大众的个人安全。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">三、关于免责声明</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          就下列相关事宜的发生，随寓将不承担任何法律责任：
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">由于您将用户密码</span>
        <span className="con-text-3">/</span>
        <span className="con-text-2">
          验证码告知他人或与他人共享注册账号，由此导致的任何个人信息的泄露，或其他非因随寓原因导致的个人信息的泄露；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">
          随寓根据法律规定或政府相关政策要求提供您的个人信息；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">
          任何第三方根据随寓各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">
          任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营而造成的个人信息泄露、丢失、被盗用或被窜改等；
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">因不可抗力导致的任何后果；</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">
          随寓在各服务条款及声明中列明的使用方式或免责情形。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">* </span>
        <span className="con-text-2">
          杭州随寓网络科技有限公司发布内容适用于其关联公司，但最终解释权归杭州随寓网络科技有限公司所有。
        </span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">四、联系我们</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-2">
          如果您对本隐私声明或随寓的隐私保护措施以及您在使用过程中的问题有任何意见和建议，敬请致电客服热线：
        </span>
        <span className="con-text-3">400-060-2897</span>
        <span className="con-text-2">。</span>
      </p>
      <p className="con-p-5">
        <span className="con-text-4">发布</span>
        <span className="con-text-5">时间：【2022-</span>
        <span className="con-text-5">05</span>
        <span className="con-text-5">-</span>
        <span className="con-text-5">20</span>
        <span className="con-text-5">】</span>
        <br />
        <span className="con-text-4">生效</span>
        <span className="con-text-5">时间：【2022-</span>
        <span className="con-text-5">05</span>
        <span className="con-text-5">-</span>
        <span className="con-text-5">20</span>
        <span className="con-text-5">】</span>
      </p>
      <p className="con-p-2">
        <span className="con-text-3">&#xa0;</span>
      </p>
    </div>
    // <div className="main-container">
    //   <p className="con-div-1">
    //     <span className="con-text">隐私声明</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       本隐私声明（包括但不限于本协议项下全部条款以及与本协议相关的通过平台发布或未来可能发布的各项规则，以下简称“本协议”）系杭州随寓网络科技有限公司（以下简称“本公司”）拟定并发布的，用于规范平台注册用户（以下简称“用户”或“您”）在平台上注册以及使用平台服务的行为。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       在您注册成为随寓用户之前，请务必确认：您是具有完全民事权利能力及民事行为能力、有能力履行并承担本协议项下的权利及义务、并能够独立作为法律诉讼的一方，您已认真阅读本协议项下全部条款，一旦您确认接受本协议，即表示您已经完全阅读、理解、同意接受并遵守本协议全部条款。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       随寓系由本公司研发运营管理，本公司对本协议享有修改及解释之权利。如本协议有任何修改变更，本公司将在随寓官网及
    //     </span>
    //     <span className="con-text">App</span>
    //     <span className="con-text">
    //       刊载相关变更事项公告，并不再单独以其它方式通知您。所有修订的条款和规则一经公告立即生效，并对您产生法律约束力。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       如您对修改变更的内容有异议，请您立即停止使用随寓并联系我们；如您继续使用随寓的任何服务，则视为您已经同意并接受所有修改变更内容。当您与随寓发生纠纷时，所有条款及规则应以最新版本为准
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       注册页面的“注册即代表已阅读并同意《随寓用户协议和隐私声明》”或首次在手机
    //     </span>
    //     <span className="con-text">app</span>
    //     <span className="con-text">软件上点击“登录”选项取得账号、密码及</span>
    //     <span className="con-text">/</span>
    //     <span className="con-text">
    //       或验证码，即视为您对本协议的签署；在任何情况下，您都不得以未签署纸质协议为由否认本协议各条款及效力。若因您对本协议的违反导致任何法律后果的发生，您应以自己的名义独立承担相应的全部责任。
    //     </span>
    //   </p>
    //   <p className="con-div-2">
    //     <span className="con-text">第一部分 范围及定义</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">一、规范性引用文件</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       下列文件对于本文件的应用是必不可少的。凡是注日期的引用文件，仅所注日期的版本适用于本文件。凡是不注日期的引用文件，其最新版本（包括所有的修改单）适用于本文件。
    //     </span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">GB/T 22239-2008</span>
    //     <span className="con-text">信息系统安全等级保护基本要求</span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">GB/T 18336-2015</span>
    //     <span className="con-text">信息技术安全评估准则</span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">GB/Z 28828-2012</span>
    //     <span className="con-text">公共及商用服务信息系统个人信息保护指南</span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">
    //       移动互联网应用程序信息服务管理规定（国家互联网信息办公室{" "}
    //     </span>
    //     <span className="con-text">2016</span>
    //     <span className="con-text">年</span>
    //     <span className="con-text">6</span>
    //     <span className="con-text">月</span>
    //     <span className="con-text">28</span>
    //     <span className="con-text">日发布）</span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">
    //       中华人民共和国网络安全法（由全国人民代表大会常务委员会于
    //     </span>
    //     <span className="con-text">2016</span>
    //     <span className="con-text">年</span>
    //     <span className="con-text">11</span>
    //     <span className="con-text">月</span>
    //     <span className="con-text">7</span>
    //     <span className="con-text">日发布，自</span>
    //     <span className="con-text">2017</span>
    //     <span className="con-text">年</span>
    //     <span className="con-text">6</span>
    //     <span className="con-text">月</span>
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       日起施行。中华人民共和国主席令（第五十三号）公布。）
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">第二部分 用户协议</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">一、注册账号</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（一）提供个人信息</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       、您有义务按照随寓的要求，及时、详尽、准确地提供您真实的身份信息及个人资料（下称个人信息），并在个人信息发生变更时及时更新，您需要提供的个人信息包括但不限于姓名、性别、年龄、联系电话、电子邮箱、目前职业、通讯地址、紧急联系人、星座、兴趣爱好等。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">2</span>
    //     <span className="con-text">
    //       、如随寓发现您提供的个人信息不完整、不真实或错误的，有权要求您及时更新、补正个人信息，或直接单方面暂时性或永久性的停止您随寓账号的部分或全部使用权限。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">3</span>
    //     <span className="con-text">
    //       、您承诺，您所提供的个人信息资料完整、真实、无误，若因信息不完整、不真实或错误产生的任何问题、不良后果（包括任何直接损失及间接损失）、法律责任均由您自行承担，随寓不承担任何责任。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（二）账号及密码</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       注册成功后，您将获得一个随寓账号（下称账号）及其对应的密码。如发现账号及密码无法匹配对应，请及时联系随寓客服
    //     </span>
    //     <span className="con-text">400-060-2897</span>
    //     <span className="con-text">。</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">二、个人隐私保护</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       尊重和保护每一位随寓客的个人隐私是随寓的基本原则。随寓将根据隐私声明之内容对您个人隐私进行保护，隐私声明属于本用户协议不可分割的一部分。您承诺已经充分阅读、理解、同意并接受随寓据此处理您的个人信息。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">三、账号安全</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（一）妥善保管账号及密码</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       、您的账号、密码及提供的个人信息是您在随寓的唯一识别信息。您注册成功后，请您严格履行对其的保密及保管，切勿将其转让、出售或授权给任何第三方使用。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">2</span>
    //     <span className="con-text">、若您主动告知他人您的账号密码</span>
    //     <span className="con-text">/</span>
    //     <span className="con-text">
    //       验证码或与他人共用账号，随寓有权认为该账号的所有操作均代表您的本人意愿，并且由此产生的任何问题、不良后果（包括直接损失及间接损失）、法律责任均由您自行承担，随寓不承担任何责任。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">3</span>
    //     <span className="con-text">
    //       、若您发现有第三人冒用、盗用您的账号及密码，应立即通知随寓停止您的账号服务，否则，由此产生的任何问题、不良后果（包括任何直接损失及间接损失）、法律责任均由您自行承担，随寓不承担任何责任。并且，由于随寓对您的请求需要采取行动的合理时间，故在随寓停止您的账号服务前，随寓对第三人使用您的账号进行的操作所带来的损失不承担任何责任
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">4</span>
    //     <span className="con-text">、您在此承诺，您的账号及密码</span>
    //     <span className="con-text">/</span>
    //     <span className="con-text">
    //       验证码在登陆随寓后，所从事的一切行为均代表您本人，并由您本人承担相应的法律后果。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（二）账号及密码修改、找回：</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       、账号密码修改：如您需修改您已注册的账号或密码，请严格按照随寓的提示信息经过身份验证（手机或电子邮箱等）后操作，或者直接致电随寓进行身份验证后，由随寓为您修改。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">2</span>
    //     <span className="con-text">
    //       、账号密码找回：如您遗忘您已经注册的账号或密码，请严格按照随寓的提示信息经过身份验证（手机或电子邮箱等）后操作，或者直接致电随寓进行身份验证后，由随寓为您重置账号密码。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">四、账号使用规范</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       您同意并承诺在使用账号时，应当遵守中华人民共和国法律法规及各项政策，维护随寓品牌形象，不会利用账号从事以下活动，或在使用账号过程中存在以下行为。否则，随寓有权立即停止您账号的部分或全部使用权限，并有权追究您相应的法律责任（若有）：
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （一）上载、展示、发送、传播、散布或者以其他方式传送含有下列内容之一的信息，随寓有权立即删除部分或全部该等信息
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       、违反中华人民共和国法律法规及各项政策的非法信息，包括但不限于危害国家安全、泄露国家秘密、颠覆国家政权、破坏宗教政策、邪教封建迷信、危害国家或社会公共利益、涉及暴力、淫秽、色情、赌博、恐怖、犯罪的信息；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">2</span>
    //     <span className="con-text">
    //       、通过捏造、编造、臆想等方式虚构的不实信息
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">3</span>
    //     <span className="con-text">
    //       、恶意诋毁、诽谤、中伤、恐吓、威胁、骚扰他人的信息
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">4</span>
    //     <span className="con-text">
    //       、侵犯他人名誉权，隐私权、知识产权、商业秘密的信息；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">5</span>
    //     <span className="con-text">
    //       、恶意诋毁、诽谤、恐吓、中伤、威胁、骚扰随寓官网管理员、杭州随寓网络科技有限公司或其员工的信息；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">6</span>
    //     <span className="con-text">
    //       、侵害随寓品牌名誉、杭州随寓网络科技有限公司商业秘密的信息；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">7</span>
    //     <span className="con-text">
    //       、其他中华人民共和国法律法规政策、社会道德、公序良俗所禁止或限制的信息。
    //     </span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">（</span>
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       ）非法利用病毒、软件、程序等破坏、侵害随寓系统，干扰随寓的正常运营的
    //     </span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">（</span>
    //     <span className="con-text">2</span>
    //     <span className="con-text">
    //       ）非法截取、盗取、改变、增删系统数据、功能、程序的；
    //     </span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">（</span>
    //     <span className="con-text">3</span>
    //     <span className="con-text">）以非法目的使用账号的；</span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">（</span>
    //     <span className="con-text">4</span>
    //     <span className="con-text">）长时间不使用；</span>
    //   </p>
    //   <p className="con-div-3">
    //     <span className="con-text">（</span>
    //     <span className="con-text">5</span>
    //     <span className="con-text">）其他危害或可能危害随寓安全的行为。</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">五、服务内容</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（一）业务介绍</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       您已理解并知悉：随寓经营的房屋租赁业务是符合中华人民共和国法律法规、地方政策及相关制度的租赁业务，且并非仅限于或仅针对某性别、某群体。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（二）房源信息展示</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       您可以在随寓上阅览、查询、搜集随寓平台上的房源及相关信息，包括房源的地址、楼栋、面积、朝向、费用价格、促销优惠等。您理解，鉴于可能存在系统更新延时等情况，该等信息可能存在不准确之情况，具体情况请以随寓管家或者与随寓联系后得到的确认信息为准。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（三）活动介绍</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       您可以通过随寓上公布的相关活动了解随寓最新的线上及线下活动信息，并根据活动规则报名参与。您理解，鉴于可能存在系统更新延时等情况，该等信息可能存在不准确之情况，具体情况请以随寓管家或者与随寓联系后得到的确认信息为准
    //     </span>
    //     <span className="con-text">;</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">（四）投诉、意见反馈</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">1</span>
    //     <span className="con-text">
    //       、投诉：您在成功注册账号后，可通过随寓特定板块进行投诉，或者直接致电随寓客服热线：
    //     </span>
    //     <span className="con-text">400-060-2897</span>
    //     <span className="con-text">
    //       ，随寓将尽快回复您，并及时安排上门维修服务或处理您的投诉
    //     </span>
    //     <span className="con-text">;</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">2</span>
    //     <span className="con-text">
    //       、意见反馈：您在成功注册账号后，可通过随寓特定板块提出您对随寓的意见和建议。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">六、免责声明</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       您已经充分阅读、理解、同意并接受，鉴于网络服务之特殊性，随寓无法担保以下内容，并且对以下内容不承担法律责任：
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （一）随寓不能保证提供的网络服务一定满足您的要求；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （二）随寓不能保证外部网络链接的真实性、安全性、准确性、完整性，因外部链接指向的为不由随寓实际控制的网页内容；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （三）随寓不能控制网络服务因所依赖的电信设备故障、不可抗力、黑客攻击、电信部门技术调整或故障、或其他随寓无法控制的原因造成的随寓部分或全部服务暂停、中断、无法使用、或者其他缺陷问题，但随寓承诺将尽力减少因此给您造成的损失和影响。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">七、知识产权声明</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （一）著作权、专利权：随寓在所提供的全部内容及网络服务的过程中，包含、涉及、提及的文本、图片、图形、图像、视频、软件、程序、程序代码等（统称网站内容）之著作权及
    //     </span>
    //     <span className="con-text">/</span>
    //     <span className="con-text">
    //       或专利权均受法律保护，未经相关权利人同意，任何自然人、法人或其他组织不得擅自直接或间接转载、引用、使用或者以其他方式复制发表发布，但法定许可的情形除外。其中属于随寓所有之部分，必须经过随寓书面授权许可。如需转载、引用、使用或者以其他方式复制发表发布随寓网站内容，或者磋商支付法定许可报酬，请联系随寓客服热线：
    //     </span>
    //     <span className="con-text">400-060-2897</span>
    //     <span className="con-text">。</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （二）商标：“随寓“以及随寓图形商标是随寓的商标及
    //     </span>
    //     <span className="con-text">/</span>
    //     <span className="con-text">
    //       或注册商标，未经随寓明确书面授权擅自非法使用（包括但不限于复制、展示、传播、发送、上载、下载）的，随寓将依法追究其法律责任。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">八、法律责任</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       因您违反本协议中的承诺、保证或任何其他义务，或因您的承诺、保证不真实、不准确、不完整或具有误导性，致使随寓遭受损失的，随寓有权要求您赔偿全部损失并依法追究您的法律责任。如造成第三方损失的，您应当独立向第三方承担相应的全部赔偿责任。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">九、通知送达</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （一）您有义务根据随寓的要求提供您的真实资料，并保证您的通讯地址、联系电话、电子邮箱地址、紧急联系人等个人通信信息的真实性及有效性，以便随寓将该等信息作为联系您的合法依据。如有该等信息有变更，您有义务及时更新您的账号信息或直接致电随寓客服热线：
    //     </span>
    //     <span className="con-text">400-060-2897</span>
    //     <span className="con-text">
    //       进行变更。如通过您提供的个人通讯信息无法联络到您，由此产生的一切损失或增加的额外费用均由您独立承担。{" "}
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （二）您同意并接受：随寓通过您提供的个人通讯信息联系您，并会定期或不定期地向您推送、告知、送达一些消息和通知。任何消息和通知于随寓发出之日起，即视为已经送达用户，您不得以任何理由否认该等信息的效力或拒绝履行该等信息下用户的相应义务。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">十、其他规定</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （一）未成年人特别提示：如您为未成年人（未满
    //     </span>
    //     <span className="con-text">18</span>
    //     <span className="con-text">
    //       周岁），随寓提示您，请遵守全国青少年网络文明公约，您无权单独使用随寓的部分或全部功能及服务，请在父母或监护人的陪同下使用随寓服务。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （二）权利义务转让：您同意随寓有权将本协议项下部分或全部权利和义务，转让给其他第三方，且无需告知您并征得您的同意。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （三）随寓未行使本协议中的任何权利，不构成对前该等权利的放弃。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （四）本用户协议中的任何条款与中国法律强制性规定冲突导致其全部或部分无效的，不影响本协议其余条款的效力。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （五）终止：除非随寓终止本协议，或您根据法律法规及本协议的约定申请终止本协议并经随寓同意，本协议长久有效。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （六）法律适用：本协议的签订、履行、变更、终止、解释均适用中华人民共和国法律。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       （七）法律管辖：因本协议或者随寓服务产生的一切纠纷及争议应向随寓所在地的人民法院提起诉讼。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">第三部分 隐私声明</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       随寓致力于尊重并保护用户的个人隐私，鉴于网络的特性，我们无可避免地需要某些信息才能为您提供您请求的服务，本隐私声明（以下简称“本声明”）将阐述我们对用户信息的收集、使用、保护和共享政策，我们建议您完整地阅读本声明。
    //       一旦您确认接受本声明，即表示您已经完全阅读、理解、同意接受本声明的全部条款。
    //       本声明适用于随寓的所有服务，随着随寓服务范围的扩大，随寓可随时更新隐私声明的内容，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效，并代替原来的隐私声明。欢迎您随时查看本声明，并向我们反馈您的意见。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">一、信息的收集与使用</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">我们将通过您的</span>
    //     <span className="con-text">IP</span>
    //     <span className="con-text">
    //       地址来收集非个人化的信息，例如您的浏览器性质、操作系统种类、给您提供接入服务的
    //     </span>
    //     <span className="con-text">ISP</span>
    //     <span className="con-text">
    //       的域名等，以优化在您手机或计算机等屏幕上显示的页面。通过收集上述信息，我们进行客流量统计，从而改进网站的管理或服务。
    //       通常，您能在匿名的状态下访问随寓并获取信息。当我们需要可用于标识您的个人身份或允许我们与您联系的信息时，我们会征求您的同意。当您在随寓进行注册登记或使用我们提供的各项服务时，您需要向我们提供您的个人信息，这些个人信息包括但不限于：
    //       个人识别信息：如姓名、性别、身份证号码、电子邮件地址，住址和电话号码等信息。
    //       个人背景：如年龄、出生日期、职业、教育程度、星座、收入状况、婚姻、家庭状况等。我们提供的服务可能包括属于我们的服务供应商的第三方追踪工具。该第三方可能会在我们提供的服务中使用
    //     </span>
    //     <span className="con-text">cookies</span>
    //     <span className="con-text">、</span>
    //     <span className="con-text">APls(</span>
    //     <span className="con-text">应用程序编程接口</span>
    //     <span className="con-text">)</span>
    //     <span className="con-text">及</span>
    //     <span className="con-text">SDKs(</span>
    //     <span className="con-text">软件程序开发包</span>
    //     <span className="con-text">)</span>
    //     <span className="con-text">
    //       ，由此代表我们收集并分析用户信息。第三方可能会访问诸如您的设备标识符、
    //     </span>
    //     <span className="con-text">MAC(</span>
    //     <span className="con-text">媒体访问控制</span>
    //     <span className="con-text">)</span>
    //     <span className="con-text">地址、</span>
    //     <span className="con-text">IME</span>
    //     <span className="con-text">、</span>
    //     <span className="con-text">IMSI</span>
    //     <span className="con-text">、</span>
    //     <span className="con-text">ME(</span>
    //     <span className="con-text">移动设备国际身份码</span>
    //     <span className="con-text">)</span>
    //     <span className="con-text">、区域</span>
    //     <span className="con-text">(</span>
    //     <span className="con-text">使用给定语言的特定地点</span>
    //     <span className="con-text">)</span>
    //     <span className="con-text">
    //       、地理位置信息及地址等信息，旨在实现在其各自隐私政策下提供服务。在某些服务中，我们将利用加密技术
    //     </span>
    //     <span className="con-text">(</span>
    //     <span className="con-text">例如</span>
    //     <span className="con-text">ssL)</span>
    //     <span className="con-text">
    //       来保护您的个人信息。我们没有权限访问或控制这些第三方。如果您想知道更多相应第三方信息，请联系我们
    //     </span>
    //     <span className="con-text">400-060-2897.</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">二、关于您的个人信息</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       请了解，随寓严格保护您个人信息的安全，一经注册，随寓仅会公开您的性别、星座、职业、爱好，您的其余个人信息将严格保密，未经您的同意，我们不会将您提供的个人信息利用于其它商业目的，我们将使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄露。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       您理解并同意，随寓仅会在法律要求或符合随寓的相关服务条款、软件许可使用协议约定的情况下透露您的个人信息，或者有充分理由相信必须这样做才能：
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">(a) </span>
    //     <span className="con-text">
    //       满足法律或行政法规的明文规定，或者符合随寓适用的法律程序；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">(b)</span>
    //     <span className="con-text">
    //       符合随寓相关服务条款、软件许可使用协议的约定；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">(c) </span>
    //     <span className="con-text">保护随寓的权利或财产；</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">(d) </span>
    //     <span className="con-text">
    //       在紧急情况下保护随寓员工、随寓产品或服务的用户或大众的个人安全。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">三、关于免责声明</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       就下列相关事宜的发生，随寓将不承担任何法律责任：
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">由于您将用户密码</span>
    //     <span className="con-text">/</span>
    //     <span className="con-text">
    //       验证码告知他人或与他人共享注册账号，由此导致的任何个人信息的泄露，或其他非因随寓原因导致的个人信息的泄露；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">
    //       随寓根据法律规定或政府相关政策要求提供您的个人信息；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">
    //       任何第三方根据随寓各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">
    //       任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营而造成的个人信息泄露、丢失、被盗用或被窜改等；
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">因不可抗力导致的任何后果；</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">
    //       随寓在各服务条款及声明中列明的使用方式或免责情形。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">* </span>
    //     <span className="con-text">
    //       杭州随寓网络科技有限公司发布内容适用于其关联公司，但最终解释权归杭州随寓网络科技有限公司所有。
    //     </span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">四、联系我们</span>
    //   </p>
    //   <p className="con-div">
    //     <span className="con-text">
    //       如果您对本隐私声明或随寓的隐私保护措施以及您在使用过程中的问题有任何意见和建议，敬请致电客服热线：
    //     </span>
    //     <span className="con-text">400-060-2897</span>
    //     <span className="con-text">。</span>
    //   </p>
    // </div>
  );
}
